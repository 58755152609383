<template>
  <div class="main" style="padding-top: 45px">
    <van-sticky :offset-top="50">
      <van-nav-bar
        title="详情"
        left-arrow
        @click-left="onClickLeft"
        fixed
        safe-area-inset-top
      ></van-nav-bar>
    </van-sticky>
    <div>
      <div v-if="active != 5">
        <div v-if="zname != '转派'">
          <van-steps v-if="roleid != 2" :active="active" active-color="#00b4e5">
            <van-step>未接单</van-step>
            <van-step>已接单</van-step>
            <van-step>已完工</van-step>
            <van-step>已评价</van-step>
          </van-steps>
          <van-steps v-if="roleid == 2" :active="active" active-color="#00b4e5">
            <van-step>未接单</van-step>
            <van-step>已接单</van-step>
            <van-step>已完工</van-step>
            <van-step>已评价</van-step>
            <van-step>已验收</van-step>
          </van-steps>
        </div>
      </div>
      <div><h5>报修信息</h5></div>
      <van-cell-group inset>
        <van-cell
          title-style="flex:0.5"
          title="报修店铺："
          :value="this.$route.query.storeName"
        />
        <van-cell title-style="flex:0.5" title="报修类型："
          ><span
            style="color: red"
            v-if="this.$route.query.z_reworkFlag == 1 ? true : false"
            >【返工】</span
          ><span>{{ this.$route.query.orderTypeName }}</span></van-cell
        >
        <van-cell
          title-style="flex:0.5"
          title="内容描述："
          :value="this.$route.query.orderContent"
        />
        <van-cell v-if="roleid == 2" title="报修人：" :value="user.truename" />
        <van-cell
          v-if="roleid == 2"
          title="联系电话："
          :value="user.phone == '' ? '暂无' : user.phone"
          ><span @click="callPhone(user.phone)">{{
            user.phone == "" ? "暂无" : user.phone
          }}</span></van-cell
        >
      </van-cell-group>

      <div v-if="isShowPhoto">
        <div><h5>报修图片：</h5></div>
        <van-cell-group inset>
          <van-uploader
            style="margin: 10px 10px 0px 10px"
            v-model="photolist"
            :show-upload="false"
            :deletable="false"
          ></van-uploader>
        </van-cell-group>
      </div>
      <div v-if="isShowVideo">
        <div><h5>报修视频：(点击下方图片可预览视频)</h5></div>
        <van-cell-group inset>
          <van-uploader
            style="margin: 10px 10px 0px 10px"
            v-model="viedolist"
            :show-upload="false"
            :deletable="false"
            accept="video/*"
            @click-preview="clickVideo"
            :preview-full-image="false"
          ></van-uploader>
        </van-cell-group>
      </div>
      <div v-if="isShowfinishedphoto">
        <div><h5>完工照片：</h5></div>
        <van-cell-group inset>
          <van-uploader
            style="margin: 10px 10px 0px 10px"
            v-model="finishedphotolist"
            :show-upload="false"
            :deletable="false"
          ></van-uploader>
        </van-cell-group>
      </div>

      <div>
        <div><h5>操作日志：</h5></div>
        <van-cell-group inset>
          <van-list :finished="true" style="margin: 10px 10px">
            <div v-for="item in statuslist" :key="item">
              <div
                style="width: 100%; overflow: hidden"
                v-if="(item.remark == '3') | (item.remark == '2')"
              >
                <div style="float: left">
                  <van-image
                    class="itemBorder"
                    round
                    width="30px"
                    height="30px"
                    :src="item.user.avatar"
                  />
                </div>
                <div v-if="item.status != '已返工'" style="width: 80%">
                  <div
                    style="
                      width: auto;
                      margin: 0px 0px 5px 40px;
                      border-radius: 5px;
                      background: #f3f3f3;
                      padding: 5px;
                    "
                  >
                    <span
                      class="minespan"
                      v-if="item.remark != 2"
                      style="margin-right: 5px"
                      >{{ item.user.storeName }}</span
                    >
                    <span class="minespan" style="margin-right: 5px">{{
                      item.user.roleName
                    }}</span>
                    <span class="minespan">{{ item.user.truename }}</span>
                    <br />
                    <span class="minespan">{{
                      this.returnStringTime(item.status)
                    }}</span>
                    <span class="minespan">{{
                      this.formatDate(item.createTime)
                    }}</span>
                    <br />
                    <div v-if="item.status == '已评价'">
                      <span class="minespan">服务评分：</span>
                      <van-rate
                        v-if="item.status == '已评价'"
                        v-model="item.evaluateScore"
                        readonly
                        :size="15"
                        color="#00b4e5"
                      />
                    </div>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      this.returnSring(item.status)
                    }}</span>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      item.orderLog == "" ? "暂无" : item.orderLog
                    }}</span>
                  </div>
                </div>
                <div v-if="item.status == '已返工'" style="width: 80%">
                  <div
                    style="
                      width: auto;
                      margin: 0px 0px 5px 40px;
                      border-radius: 5px;
                      background: #f3f3f3;
                      padding: 5px;
                      border: 2px solid red;
                    "
                  >
                    <span
                      class="minespan"
                      v-if="item.remark != 2"
                      style="margin-right: 5px"
                      >{{ item.user.storeName }}</span
                    >
                    <span class="minespan" style="margin-right: 5px">{{
                      item.user.roleName
                    }}</span>
                    <span class="minespan">{{ item.user.truename }}</span>
                    <br />
                    <span class="minespan">{{
                      this.returnStringTime(item.status)
                    }}</span>
                    <span class="minespan">{{
                      this.formatDate(item.createTime)
                    }}</span>
                    <br />
                    <div v-if="item.status == '已评价'">
                      <span class="minespan">服务评分：</span>
                      <van-rate
                        v-if="item.status == '已评价'"
                        v-model="item.evaluateScore"
                        readonly
                        :size="15"
                        color="#00b4e5"
                      />
                    </div>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      this.returnSring(item.status)
                    }}</span>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      item.orderLog == "" ? "暂无" : item.orderLog
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                style="width: 100%; overflow: hidden"
                v-if="
                  (item.remark == '0') |
                    (item.remark == '1') |
                    (item.remark == null)
                "
              >
                <div style="float: right">
                  <van-image
                    class="itemBorder"
                    round
                    width="30px"
                    height="30px"
                    :src="item.user.avatar"
                  />
                </div>
                <div
                  v-if="item.status != '已返工'"
                  style="width: 80%; float: right"
                >
                  <div
                    style="
                      margin: 0px 10px 5px 0px;
                      background: #68dfff;
                      border-radius: 5px;
                      padding: 5px;
                    "
                  >
                    <span class="minespan" style="margin-right: 5px">{{
                      item.user.roleName
                    }}</span>
                    <span class="minespan">{{ item.user.truename }}</span>
                    <br />
                    <span class="minespan">{{
                      this.returnStringTime(item.status)
                    }}</span>
                    <span class="minespan">{{
                      this.formatDate(item.createTime)
                    }}</span>
                    <br />
                    <div v-if="item.status == '已评价'">
                      <span class="minespan">服务评分：</span>
                      <van-rate
                        v-if="item.status == '已评价'"
                        v-model="item.evaluateScore"
                        readonly
                        :size="15"
                        color="#00b4e5"
                      />
                    </div>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      this.returnSring(item.status)
                    }}</span>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      item.orderLog == "" ? "暂无" : item.orderLog
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="item.status == '已返工'"
                  style="width: 80%; float: right"
                >
                  <div
                    style="
                      width: auto;
                      margin: 0px 10px 5px 40px;
                      border-radius: 5px;
                      background: #f3f3f3;
                      padding: 5px;
                      border: 2px solid red;
                    "
                  >
                    <span class="minespan" style="margin-right: 5px">{{
                      item.user.roleName
                    }}</span>
                    <span class="minespan">{{ item.user.truename }}</span>
                    <br />
                    <span class="minespan">{{
                      this.returnStringTime(item.status)
                    }}</span>
                    <span class="minespan">{{
                      this.formatDate(item.createTime)
                    }}</span>
                    <br />
                    <div v-if="item.status == '已评价'">
                      <span class="minespan">服务评分：</span>
                      <van-rate
                        v-if="item.status == '已评价'"
                        v-model="item.evaluateScore"
                        readonly
                        :size="15"
                        color="#00b4e5"
                      />
                    </div>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      this.returnSring(item.status)
                    }}</span>
                    <span class="minespan" v-if="item.status != '未接单'">{{
                      item.orderLog == "" ? "暂无" : item.orderLog
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-cell-group>
        <br />
      </div>
      <van-button
        v-if="showCancle"
        style="
          width: 95%;
          margin-left: 10px;
          margin-top: 50px;
          margin-bottom: 10px;
        "
        round
        type="danger"
        size="large"
        color="rgb(30, 177, 227)"
        @click="submitCancel()"
        >取消</van-button
      >
      <van-button
        v-if="showEvaluation"
        style="
          width: 95%;
          margin-left: 10px;
          margin-top: 50px;
          margin-bottom: 10px;
        "
        round
        type="primary"
        size="large"
        color="rgb(30, 177, 227)"
        @click="submitEvaluation()"
        >评价</van-button
      >
      <div v-if="showReturn" style="margin-top: 50px">
        <van-button
          class="successBtn"
          type="primary"
          size="normal"
          round
          @click="AcceptanceCilck"
          >验收</van-button
        >
        <van-button
          class="successBtn"
          type="danger"
          size="normal"
          round
          @click="ReworkCilck"
          >返工</van-button
        >
      </div>
      <van-dialog
        v-model:show="showevaldialog"
        message="是否验收通过？"
        show-cancel-button
        confirm-button-text="是"
        cancel-button-text="否"
        @confirm="EvalSure"
      >
      </van-dialog>
      <van-popup v-model:show="showCanclePicker" round position="bottom">
        <van-picker
          :columns="canclePicker"
          @cancel="onCancleCancle"
          @confirm="onCancleConfirm"
        />
      </van-popup>
      <van-popup v-model:show="showReWorkPicker" round position="bottom">
        <van-picker
          :columns="reWorkPicker"
          @cancel="onReworkCancle"
          @confirm="onReworkConfirm"
        />
      </van-popup>
      <van-popup @click-overlay="beclose" v-model:show="showVideo">
        <div>
          <video
            width="320"
            height="240"
            controls
            autoplay
            muted="false"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="allow"
            x5-video-player-type="h5-page"
            x5-video-orientation="portraint"
            style="object-fit: cover"
            ref="video"
          >
            <source :src="viedeoUrl" />
            <!-- type="video/mp4" -->
          </video>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { OrderDetails, ReceiveOrder, AcceptanceOrRework } from "../api";
export default {
  data() {
    return {
      showCanclePicker: false,
      showReWorkPicker: false,
      canclePicker: [
        "报修描述有误",
        "报修内容缺失",
        "报修图片或视频有误",
        "报修重复",
        "其他",
      ],
      reWorkPicker: [
        "验收不合格",
        "完工不及时",
        "完工质量不满意",
        "服务态度不满意",
        "其他",
      ],
      showevaldialog: false,
      showCancle: false,
      showEvaluation: false,
      showReturn: false,
      showReturnWork: false,
      active: this.$route.query.z_active,
      roleid: this.$route.query.z_roleid,
      zname: this.$route.query.z_name,
      user: {},
      i: 0,
      photolist: [],
      isShowPhoto: false,
      finishedphotolist: [],
      isShowfinishedphoto: false,
      viedolist: [],
      viedoURLlist: [],
      showVideo: false,
      isShowVideo: false,
      statuslist: [],
      scoreList: [],
      receivingTime: "",
      receivingDescription: "",
      isShowReceiving: false,
      completionTime: "",
      completionDescription: "",
      isShowCompletion: false,
      evaluateTime: "",
      evaluateDescription: "",
      isShowEvaluate: false,
      scorevalue: 0,
      returnTime: "",
      returnDescription: "",
      isShowReturn: false,
    };
  },
  methods: {
    onClickLeft() {
      // 跳转上一级。
      this.$router.back();
    },
    // 关闭
    beclose() {
      this.showVideo = false;
      this.$refs.video.pause();
    },
    // 电话
    callPhone(phone) {
      if (phone < 8) {
        Toast("电话号码不正确，无法拨打");
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.callPhoneiOS.postMessage({
            title: phone, //vue给iOS传值
          });
        } else {
          window.location.href = "tel:" + phone;
        }
      }
    },
    returnStringTime(string) {
      console.log(string);
      switch (string) {
        case "未接单":
          return "报修时间：";
        case "已接单":
          return "接单时间：";
        case "已完工":
          return "完工时间：";
        case "已评价":
          return "评价时间：";
        case "已返工":
          return "返工时间：";
        case "已转派":
          return "转派时间：";
        case "已验收":
          return "验收时间：";
        default:
          break;
      }
    },
    returnSring(string) {
      switch (string) {
        case "未接单":
          return "报修时间：";
        case "已接单":
          return "接单描述：";
        case "已完工":
          return "完工描述：";
        case "已评价":
          return "服务评价：";
        case "已返工":
          return "返工描述：";
        case "已转派":
          return "转派描述：";
        case "已验收":
          return "验收描述：";
        default:
          break;
      }
    },
    EvalSure() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      AcceptanceOrRework({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
        acceptanceorrework: 1,
      })
        .then((result) => {
          if (result.code == 1) {
            Toast(result.data);
            this.$router.go(-1);
          }
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },

    submitCancel() {
      this.showCanclePicker = true;
    },
    onCancleCancle() {
      this.showCanclePicker = false;
    },
    onCancleConfirm(value) {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      ReceiveOrder({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
        receivecontent: value,
      })
        .then((result) => {
          if (result.code == 1) {
            this.showCanclePicker = false; // 在这里手动的关闭弹窗
            Toast(result.data);
            this.$router.go(-1);
          }
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
          Toast.clear();
        });
    },
    submitEvaluation() {
      this.$router.push({
        path: "/EvaluationView",
        query: this.$route.query,
      });
    },
    AcceptanceCilck() {
      this.showevaldialog = true;
    },

    ReworkCilck() {
      this.showReWorkPicker = true;
    },
    onReworkConfirm(value) {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      AcceptanceOrRework({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
        acceptanceorrework: 2,
        receivecontent: value,
      })
        .then((result) => {
          if (result.code == 1) {
            Toast(result.data);
            this.$router.go(-1);
          }
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast.clear();
          Toast("网络错误");
        });
    },
    onReworkCancle() {
      this.showReWorkPicker = false;
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        let minutes =
          dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " " +
          hours +
          ":" +
          minutes
          //+
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    getDateList() {
      OrderDetails({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            if (result.data.photoList != null) {
              result.data.photoList.forEach((item) => {
                this.photolist.push(item);
              });
            }
            this.user = result.data.user;
            if (this.photolist.length > 0) {
              this.isShowPhoto = true;
            } else {
              this.isShowPhoto = false;
            }
            if (result.data.finishedPhotoList != null) {
              result.data.finishedPhotoList.forEach((item) => {
                this.finishedphotolist.push(item);
              });
            }
            if (this.finishedphotolist.length > 0) {
              this.isShowfinishedphoto = true;
            } else {
              this.isShowfinishedphoto = false;
            }
            if (result.data.videoList.length != 0) {
              result.data.videoList.forEach((item) => {
                this.viedoURLlist.push(item.url);
                if (result.data.previewList.length == 0) {
                  this.viedolist.push({
                    url: "https://sopimg.dbshopplus.com/group1/M00/00/7B/wKgAL2KXG3OAU7LAAAAb9T3XmiE25.jpeg",
                  });
                }
              });
              this.isShowVideo = true;
            } else {
              this.isShowVideo = false;
            }
            if (result.data.previewList.length != 0) {
              result.data.previewList.forEach((item) => {
                console.log(item);
                if (item.url) {
                  this.viedolist.push({
                    url: item.url,
                  });
                } else {
                  this.viedolist.push({
                    url: "https://sopimg.dbshopplus.com/group1/M00/00/7B/wKgAL2KXG3OAU7LAAAAb9T3XmiE25.jpeg",
                  });
                }
                console.log(item.url);
                this.viedoURLlist.push(item.url);
              });
            }
            if (result.data.scoreList != null) {
              result.data.scoreList.forEach((item) => {
                this.scoreList.push(item.evaluateScore);
              });
            }
            if (result.data.statusList != null) {
              result.data.statusList.forEach((item) => {
                this.statuslist.push(item);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    // 点击视频预览
    clickVideo(detail, index) {
      console.log(this.viedoURLlist);
      console.log(index.index);
      if (this.viedoURLlist[index.index]) {
        this.showVideo = true;
        this.viedeoUrl = this.viedoURLlist[index.index];
        // console.log(this.viedeoUrl);
        this.$refs.video.src = this.viedeoUrl;
      } else {
        Toast("视频无法播放");
      }
    },
  },
  mounted() {
    this.getDateList();
    //判断jim是否展示取消
    if (this.$route.query.z_reworkFlag == 1) {
      this.showCancle == false;
    } else {
      if (this.$route.query.z_active == 0) {
        if (this.$route.query.z_roleid == 2) {
          this.showCancle = false;
        } else {
          this.showCancle = true;
        }
      } else {
        this.showCancle = false;
      }
    }
    //判断jim是否展示评价
    if (this.$route.query.z_active == 2) {
      if (this.$route.query.z_roleid == 2) {
        this.showEvaluation = false;
      } else {
        this.showEvaluation = true;
      }
    } else {
      this.showEvaluation = false;
    }
    //判断jim是否展示返工
    if (this.$route.query.z_active == 3) {
      if (this.$route.query.z_roleid == 2) {
        this.showReturn = true;
      } else {
        this.showReturn = false;
      }
    } else {
      this.showReturn = false;
    }
    // console.log(this.$route.query.z_status);
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: max-content;
  background-color: #f8f8f8;
}
div h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
  /* background-color: clear; */
}
:deep(.van-cell) {
  font-size: 14px;
}

:deep(.van-cell__value) {
  color: rgb(150, 151, 153) !important;
}
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
:deep(.van-step--horizontal .van-step__title) {
  font-size: 14px;
}
.itemBorder {
  border: 2px solid #00b4e5;
  border-radius: 25px;
}
.successBtn {
  width: 40%;
  margin: 10px 5% 10px 5%;
}
.minespan {
  word-break: break-all;
  color: #333333;
  font-size: 14px;
}
#wai {
  width: 90%;
  overflow: hidden;
}
.divleft {
  float: left;
  width: 30%;
}
.divright {
  float: right;
  width: 70%;
}
</style>
