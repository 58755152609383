<template>
  <div class="OrderList" style="padding-top: 45px">
    <van-nav-bar
      title="订单列表"
      left-arrow
      :right-text="this.$route.query[3] == 2 ? '' : '报修'"
      @click-left="onClickBack"
      @click-right="onClickRight"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <!--    @click-right="ticketArrears"
      right-text="欠票信息"
    -->
    <!-- <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch" 
    >
      <template #action>
        <div @click="onClickButton">搜索</div>
      </template>
    </van-search> -->
    <!-- 滑动swipeable -->
    <van-tabs
      v-if="roleid == 2"
      style="width: 100%; position: relative"
      v-model:active="active"
      swipeable
      sticky
      :v-show="true"
      swipe-threshold="6"
      offset-top="46px"
      ref="tabs"
      title-active-color="#00b4e5"
      @click="change"
    >
      <van-tab
        v-for="item in tabLeaderList"
        :key="item"
        :title="item.name"
      >
        <order-list :channel="item"></order-list>
      </van-tab>
    </van-tabs>
    <van-tabs
      v-if="roleid == 3"
      style="width: 100%; position: relative"
      v-model:active="active"
      swipeable
      sticky
      :v-show="true"
      swipe-threshold="5"
      offset-top="46px"
      ref="tabs"
      title-active-color="#00b4e5"
      @click="change"
    >
      <van-tab
        v-for="item in tabList"
        :key="item"
        :title="item.name"
      >
        <order-list :channel="item"></order-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import orderList from "./OrderList.vue";
// import { Toast } from "vant";
// import { OrderList } from "../api";
export default {
  components: { orderList },
  name: "MapContainer",
  data() {
    return {
      active: 0,
      usetList: this.$route.query,
      roleid: this.$route.query[3],
      tabList: [
        {
          name: "未接单",
          z_name: "报修",
          z_active: 0,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已接单",
          z_name: "接单",
          z_active: 1,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已完工",
          z_name: "完工",
          z_active: 2,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已评价",
          z_name: "评价",
          z_active: 3,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已转派",
          z_name: "转派",
          z_active: 4,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
      ],
      tabLeaderList: [
        {
          name: "未接单",
          z_name: "报修",
          z_active: 0,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已接单",
          z_name: "接单",
          z_active: 1,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已完工",
          z_name: "完工",
          z_active: 2,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已评价",
          z_name: "评价",
          z_active: 3,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已验收",
          z_name: "验收",
          z_active: 4,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
        {
          name: "已转派",
          z_name: "转派",
          z_active: 5,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_roleid: this.$route.query[3],
        },
      ],
    };
  },
  methods: {
    change(name) {
      console.log("active1" + name);
      sessionStorage.setItem("active", name);
      // localStorage.active = name;
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    onClickRight() {
      this.$route.query.repair = "repair";
      this.$router.push({
        path: "/RepairView",
        query: this.$route.query,
      });
    },

    // ticketArrears() {
    //   this.$router.push({
    //     path: "/OweTicketCenter",
    //     query: this.detailsHomeData,
    //   });
    // },
  },
  created() {
    console.log("updated");
    setTimeout(() => {
      console.log("ssss");
      this.$refs.tabs.resize();
    }, 1);
  },
  mounted: function () {
    console.log("mounted");
    // this.active = 0;
    // 从 sessionStorage 获取数据
    let data = "";
    switch (sessionStorage.getItem("active")) {
      case "1":
        data = 1;
        break;
      case "2":
        data = 2;
        break;
      case "3":
        data = 3;
        break;
      case "4":
        data = 4;
        break;
      case "5":
        data = 5;
        break;
      default:
        data = 0;
        break;
    }
    this.active = data;
  },
};
</script>

<style scoped>
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-tabs__line) {
  background: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
