import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "./components/HomePage.vue";
import FlashPage from "./components/FlashPage.vue";
import ListDetails from "./components/ListDetails.vue";
import RepairView from "./components/RepairView.vue";
import EvaluationView from "./components/EvaluationView.vue";
import StoreView from "./components/StoreView.vue";
import OrderSearch from "./components/OrderSearch.vue";
import SearchList from "./components/SearchList.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: FlashPage,
    },
    {
      path: "/homePage",
      component: HomePage,
      meta: {
        keepAlive: true // 需要缓存页面
      }
    },
    {
      path: "/ListDetails",
      component: ListDetails,
    },
    {
      path: "/RepairView",
      component: RepairView,
    },
    {
      path: "/EvaluationView",
      component: EvaluationView,
    },
    {
      path: "/StoreView",
      component: StoreView,
    },
    {
      path: "/OrderSearch",
      component: OrderSearch,
    },
    {
      path: "/SearchList",
      component: SearchList,
    },
  ],
});

export default router;
