<template>
  <div class="main" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="报修"
      left-arrow
      @click-left="onClickBack(result, title)"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <!-- 计划进场开始日期 -->
    <br />
    <van-cell-group inset>
      <van-field
        v-model="result"
        is-link
        readonly
        label="报修类型"
        placeholder="请选择报修类型"
        @click="showPicker = true"
      />

      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>

    <br />

    <!-- 填写拒单理由 -->

    <van-cell-group inset>
      <van-field
        v-model="title"
        rows="4"
        autosize
        label="内容描述"
        type="textarea"
        placeholder="请输入描述"
        maxlength="100"
        show-word-limit
      />
    </van-cell-group>

    <div>
      <h5>上传照片</h5>
      <h6>
        支持拍照或从相册选择照片上传（部分机型仅支持从相册选择），最多9张照片
      </h6>
    </div>
    <!-- <div style="line-height: 0.4rem">{{ title.length }}/50</div> -->
    <!-- 提交按钮 -->
    <van-cell-group inset>
      <van-uploader
        class="preview-cover"
        v-model="photoList"
        :after-read="afterReadPhoto"
        :before-delete="beforeDeletePhone"
        :max-size="15 * 1024 * 1024"
        @oversize="onOversize"
        multiple
        :max-count="9"
      />
    </van-cell-group>
    <div>
      <h5>上传视频</h5>
      <h6>
        仅支持上传视频（上传非视频格式时无法提交报修单且部分机型仅支持从相册选择），上传成功后点击图片可预览视频
      </h6>
    </div>
    <van-cell-group inset>
      <van-uploader
        class="preview-cover"
        v-model="videoList"
        :after-read="afterReadVideo"
        :before-delete="beforeDeleteVideo"
        :max-size="50 * 1024 * 1024"
        @oversize="onViodeOversize"
        accept="video/*"
        :max-count="9"
        @click-preview="clickVideo"
        :preview-full-image="false"
        upload-icon="video-o"
      />
    </van-cell-group>
    <br />
    <van-button
      style="
        width: 95%;
        margin-left: 10px;
        margin-top: 50px;
        margin-bottom: 10px;
        position: fixed;
        left: 0px;
        bottom: 0;
        _position: absolute;
      "
      round
      type="primary"
      size="large"
      color="rgb(30, 177, 227)"
      @click="submit(result, reindex, title)"
      :disabled="disable"
      >报修</van-button
    >
    <van-popup @click-overlay="beclose" v-model:show="showVideo">
      <!-- <div style="width: 320; height: 230; background: rgba(0, 0, 0, 0.3)"> -->
      <video
        width="320"
        height="240"
        controls
        autoplay
        muted="false"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="allow"
        x5-video-player-type="h5-page"
        x5-video-orientation="portraint"
        style="object-fit: cover"
        ref="video"
      >
        <source :src="viedeoUrl" />
        <!-- type="video/mp4" -->
      </video>
      <!-- </div> -->
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
import { Dialog, Toast } from "vant";
import { ref } from "vue";
import {
  OrderDeletephoto,
  OrderAddorder,
  OrderOrdertype,
  OrderAddphoto,
  // orderAddfilephoto,
} from "../api";
import { compressImage } from "../components/config/utils";
// import compressor from "compressorjs";

export default {
  setup() {
    const result = ref("");
    const reindex = ref("");
    const showPicker = ref(false);

    const onConfirm = (value, index) => {
      reindex.value = index;
      result.value = value;
      showPicker.value = false;
    };

    const value = ref("");

    return {
      result,
      reindex,
      onConfirm,
      showPicker,
      value,
    };
  },

  data() {
    return {
      title: "",
      showVideo: false,
      viedeoUrl: "",
      videoImg: "",
      photoList: [],
      photoURLList: [],
      videoList: [],
      // videoList1: [],
      videoURLList: [],
      previewList: [],
      videoPlaceImage: "",
      columns: [],
      columnsid: [],
      disable: false,
      dateTime: "",
      // urlString: "http://192.168.31.185:8083",
      urlString: "https://chkapi.dbshopplus.com",
    };
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("图片大小不能超过15M，请您重新拍照上传");
    },
    onViodeOversize(file) {
      console.log(file);
      Toast("视频大小不能超过50M，请您重新上传");
    },
    // 上传照片
    afterReadPhoto(file, index) {
      const that = this;
      // console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      // let photo = [];
      if (file.length > 1) {
        // console.log(11111111);
        file.forEach((item, index) => {
          console.log(item);
          const isImage = item.file.type.includes("image");
          if (!isImage) {
            Toast("上传文件类型必须是图片格式");
            this.photoList.splice(index.index, 1);
            return;
          }
          Toast.loading({
            message: "上传中...",
            forbidClick: true,
            duration: 0,
          });
          // let f = item.file;
          // new compressor(f, {
          //   quality: 0.6,
          //   convertSize: 1000000,
          //   // The compression process is asynchronous,
          //   // which means you have to access the `result` in the `success` hook function.
          //   success(result) {
          //     let fi = new File([result], f.name, {
          //       type: f.type,
          //     });
          //     let formdata = new FormData();
          //     formdata.append("photo", fi);
          //     formdata.append("token", that.$route.query[1]);
          //     formdata.append("userid", that.$route.query[0]);
          //     let config = {
          //       headers: {
          //         //添加请求头
          //         "Content-Type": "multipart/form-data",
          //       },
          //     };
          //     axios({
          //       url: that.urlString + "/order/addfilephoto",
          //       method: "post",
          //       data: formdata,
          //       config,
          //       cache: false,
          //       contentType: false,
          //       processData: false,
          //       dataType: "json",
          //       timeout: 20000,
          //       withCredentials: false,
          //     })
          //       .then(function (res) {
          //         Toast.clear();
          //         console.log(res.data.data);
          //         if (res.data.code == 1) {
          //           file.status = "done";
          //           that.photoURLList.push(res.data.data);
          //           // that.photoList[index.index] = {
          //           //   url: res.data.data,
          //           // };
          //         } else {
          //           file.status = "failed";
          //           file.message = "上传失败";
          //         }
          //       })
          //       .catch((err) => {
          //         Toast.clear();
          //         console.log(err);
          //       });
          //   },
          //   error(err) {
          //     Toast.clear();
          //     console.log(err.message);
          //   },
          // });
          // let formdata = new FormData();
          // formdata.append("photo", item.file);
          // formdata.append("token", that.$route.query[1]);
          // formdata.append("userid", that.$route.query[0]);
          // let config = {
          //   headers: {
          //     //添加请求头
          //     "Content-Type": "multipart/form-data",
          //   },
          // };
          // axios({
          //   url: this.urlString + "/order/addfilephoto",
          //   method: "post",
          //   data: formdata,
          //   config,
          //   cache: false,
          //   contentType: false,
          //   processData: false,
          //   dataType: "json",
          //   timeout: 20000,
          //   withCredentials: false,
          // })
          //   .then(function (res) {
          //     // console.log(data.data.dat);
          //     if (res.data.code == 1) {
          //       file.status = "done";
          //       that.photoURLList.push(res.data.data);
          //     } else {
          //       file.status = "failed";
          //       file.message = "上传失败";
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          compressImage(item.content, item.file.size).then((result) => {
            OrderAddphoto({
              token: this.$route.query[1],
              userid: this.$route.query[0],
              photo: result,
            })
              .then((result) => {
                if (file.length - 1 == index) {
                  Toast.clear();
                }
                if (result.code == 1) {
                  file.status = "done";
                  this.photoURLList.push(result.data);
                } else {
                  file.status = "failed";
                  file.message = "上传失败";
                }
              })
              .catch((err) => {
                if (file.length - 1 == index) {
                  Toast.clear();
                }
                console.log(err);
              });
          });
        });
      } else {
        console.log(222222222);
        // console.log(file);
        const isImage = file.file.type.includes("image");
        if (!isImage) {
          Toast("上传文件类型必须是图片格式");
          that.photoList.splice(index.index, 1);
          return;
        }
        Toast.loading({
          message: "上传中...",
          forbidClick: true,
          duration: 0,
        });
        // let formdata = new FormData();
        // formdata.append("photo", file.file);
        // formdata.append("token", that.$route.query[1]);
        // formdata.append("userid", that.$route.query[0]);
        // let config = {
        //   headers: {
        //     //添加请求头
        //     "Content-Type": "multipart/form-data",
        //   },
        // };
        // axios({
        //   url: this.urlString + "/order/addphoto",
        //   method: "post",
        //   data: formdata,
        //   config,
        //   cache: false,
        //   contentType: false,
        //   processData: false,
        //   dataType: "json",
        //   timeout: 20000,
        //   withCredentials: false,
        // })
        //   .then(function (res) {
        //     console.log(res.data.data);
        //     if (res.data.code == 1) {
        //       file.status = "done";
        //       that.photoURLList.push(res.data.data);
        //       // that.photoList[index.index] = {
        //       //   url: res.data.data,
        //       // };
        //     } else {
        //       file.status = "failed";
        //       file.message = "上传失败";
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        compressImage(file.content, file.file.size).then((result) => {
          console.log(result);
          // console.log("压缩后的结果", result); // result即为压缩后的结果
          // console.log("压缩前大小", item.file.size);
          OrderAddphoto({
            token: this.$route.query[1],
            userid: this.$route.query[0],
            photo: result,
          })
            .then((result) => {
              Toast.clear();
              if (result.code == 1) {
                file.status = "done";
                this.photoURLList.push(result.data);
              } else {
                file.status = "failed";
                file.message = "上传失败";
              }
            })
            .catch((err) => {
              Toast.clear();
              console.log(err);
            });
          // photo.push(result);
        });
        // let primaryFile = file.file;
        // new compressor(primaryFile, {
        //   quality: 0.6,
        //   convertSize: 500000,
        //   // The compression process is asynchronous,
        //   // which means you have to access the `result` in the `success` hook function.
        //   success(result) {
        //     console.log(result);
        //     let creatFile = new File([result], primaryFile.name, {
        //       type: primaryFile.type,
        //     });
        //     let formdata = new FormData();
        //     formdata.append("photo", creatFile);
        //     formdata.append("token", that.$route.query[1]);
        //     formdata.append("userid", that.$route.query[0]);
        //     let config = {
        //       headers: {
        //         //添加请求头
        //         "Content-Type": "multipart/form-data",
        //       },
        //     };
        //     axios({
        //       url: that.urlString + "/order/addfilephoto",
        //       method: "post",
        //       data: formdata,
        //       config,
        //       cache: false,
        //       contentType: false,
        //       processData: false,
        //       dataType: "json",
        //       timeout: 20000,
        //       withCredentials: false,
        //     })
        //       .then(function (res) {
        //         Toast.clear();
        //         console.log(res.data.data);
        //         if (res.data.code == 1) {
        //           file.status = "done";
        //           that.photoURLList.push(res.data.data);
        //           // that.photoList[index.index] = {
        //           //   url: res.data.data,
        //           // };
        //         } else {
        //           file.status = "failed";
        //           file.message = "上传失败";
        //         }
        //       })
        //       .catch((err) => {
        //         Toast.clear();
        //         console.log(err);
        //       });
        //   },
        //   error(err) {
        //     Toast.clear();
        //     console.log(err.message);
        //   },
        // });
      }
      // console.log(photo);
    },
    // 删除照片
    beforeDeletePhone(index, detail) {
      // console.log(this.beforeCounterPhotoList[detail.index]);
      // console.log(detail.index);
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(() => {
          OrderDeletephoto({
            token: this.$route.query[1],
            userid: this.$route.query[0],
            photourl: this.photoURLList[detail.index],
          })
            .then((result) => {
              if (result.code == 1) {
                Toast("删除成功");
                this.photoList.splice(detail.index, 1);
                this.photoURLList.splice(detail.index, 1);
              } else {
                this.photoList.splice(detail.index, 1);
                if (result.message) {
                  Toast(result.message);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    afterReadVideo(file, index) {
      const isVideo = file.file.type.includes("video");
      if (!isVideo) {
        Toast("上传文件类型必须是视频格式");
        this.videoList.splice(index.index, 1);
        return;
      }
      // 将选中的上传文件转化为二进制文件，显示在页面上'
      // let uploadUrl = URL.createObjectURL(file.file);
      // that.getVideoBase64(uploadUrl);
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
      });
      // setTimeout(1000);
      if (file.length > 1) {
        file.forEach((item) => {
          // // 实例化
          let formdata = new FormData();
          formdata.append("video", item.file);
          formdata.append("token", this.$route.query[1]);
          formdata.append("userid", this.$route.query[0]);
          let config = {
            headers: {
              //添加请求头
              "Content-Type": "multipart/form-data",
            },
          };
          axios
            .post(this.urlString + "/order/addvideo", formdata, config)
            .then((res) => {
              Toast.clear();
              if (res.data.code == 1) {
                file.status = "done";
                // this.videoList.push(res.data.data);
                this.videoURLList.push(res.data.data);
              } else {
                // console.log(222);
                file.status = "failed";
                file.message = "上传失败";
              }
            })
            .catch((err) => {
              Toast.clear();
              console.log(err);
              Toast("网络错误");
            });
        });
      } else {
        // // 实例化
        let formdata = new FormData();
        formdata.append("video", file.file);
        formdata.append("token", this.$route.query[1]);
        formdata.append("userid", this.$route.query[0]);
        formdata.append("filepath", "/mnt/uploads/");
        formdata.append("filename", this.getdateTime());
        let config = {
          headers: {
            //添加请求头
            "Content-Type": "multipart/form-data",
          },
        };
        axios
          .post(this.urlString + "/order/addvideo", formdata, config)
          .then((res) => {
            Toast.clear();
            if (res.data.code == 1) {
              // console.log(res.data.data.videoPath);
              this.videoURLList.push(res.data.data.videoPath);
              // 如果上传成功了替换当前数组位置
              file.status = "done";
              if (
                res.data.data.primaryPhoto == "" ||
                res.data.data.primaryPhoto == null
              ) {
                this.videoList[index.index] = {
                  url: "https://chknotice.dbshopplus.com/vedio.png",
                };
                this.previewList.push(
                  "https://chknotice.dbshopplus.com/vedio.png"
                );
              } else {
                this.videoList[index.index] = {
                  url: res.data.data.primaryPhoto,
                };
                this.previewList.push(res.data.data.primaryPhoto);
              }

              // this.findvideocover(res.data.data);
            } else {
              // console.log(222);
              Toast(res.data.message);
              file.status = "failed";
              file.message = "上传失败";
            }
          })
          .catch((err) => {
            Toast.clear();
            console.log(err);
            Toast("网络错误");
          });
      }
    },
    //截取视频某帧
    getVideoBase64(url) {
      const that = this;
      that.videoPlaceImage = "";
      let dataURL = "";
      let video = document.createElement("video");
      // console.log("video" + video.width);
      video.setAttribute("crossOrigin", "anonymous"); //处理跨域
      video.setAttribute("src", url);
      video.setAttribute("height", 200);
      video.setAttribute("width", 150);
      // video.setAttribute("preload", "auto");
      video.currentTime = 0.5;
      video.addEventListener("loadeddata", function () {
        // Toast(2222);
        let canvas = document.createElement("canvas");
        let width = video.width; //canvas的尺寸和图片一样
        let height = video.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
        dataURL = canvas.toDataURL("image/png"); //转换为base64

        let formdata = new FormData();
        formdata.append("photo", dataURL);
        formdata.append("token", that.$route.query[1]);
        formdata.append("userid", that.$route.query[0]);
        let config = {
          headers: {
            //添加请求头
            "Content-Type": "multipart/form-data",
          },
        };
        axios({
          url: this.urlString + "/order/addphoto",
          method: "post",
          data: formdata,
          config,
          cache: false,
          contentType: false,
          processData: false,
          dataType: "json",
          timeout: 20000,
          withCredentials: false,
        })
          .then(function (data) {
            if (data.data.code == 1) {
              that.videoPlaceImage = data.data.data;
            } else {
              that.videoPlaceImage =
                "https://chknotice.dbshopplus.com/vedio.png";
            }
          })
          .catch((err) => {
            that.videoPlaceImage = "https://chknotice.dbshopplus.com/vedio.png";
            console.log(err);
          });
      });
    },
    beforeDeleteVideo(index, detail) {
      // OrderAddorder
      // this.photoList.splice(detail.index, 1);
      Dialog.confirm({
        title: "",
        message: "是否删除该视频",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(() => {
          OrderDeletephoto({
            token: this.$route.query[1],
            userid: this.$route.query[0],
            photourl: this.videoURLList[detail.index],
          })
            .then((result) => {
              if (result.code == 1) {
                Toast("删除成功");
                this.videoList.splice(detail.index, 1);
                this.videoURLList.splice(detail.index, 1);
                this.previewList.splice(detail.index, 1);
              } else {
                this.videoList.splice(detail.index, 1);
                if (result.message) {
                  Toast(result.message);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 点击视频预览
    clickVideo(detail, index) {
      // // if (this.videoURLList.length > 0) {
      // //   this.viedeoUrl = this.videoURLList[index];
      // // }
      // console.log(detail);
      if (this.videoURLList[index.index]) {
        this.showVideo = true;
        this.viedeoUrl = this.videoURLList[index.index];
        // console.log(this.viedeoUrl);
        this.$refs.video.src = this.viedeoUrl;
      } else {
        Toast("视频无法播放，请删除后重新上传");
      }
    },
    // 关闭
    beclose() {
      this.showVideo = false;
      this.$refs.video.pause();
    },

    submit(result, reindex, title) {
      console.log(this.photoURLList);

      if (result == "") {
        Toast("请选择报修类型");
        return;
      }
      if (title == "") {
        Toast("请输入内容描述");
        return;
      }
      if (this.videoURLList.length < 1 && this.photoURLList.length < 1) {
        Toast("请上传照片或视频");
        return;
      }
      this.disable = true;
      Dialog.confirm({
        title: "",
        message: "是否确认报修内容已全部填写完成",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(() => {
          Toast.loading({
            message: "上传中...",
            forbidClick: true,
            duration: 0,
          });
          // if (this.beforeCounterPhotoList[detail.index].id) {
          OrderAddorder({
            token: this.$route.query[1],
            userid: this.$route.query[0],
            content: title,
            typeid: this.columnsid[reindex],
            photolist: this.photoURLList.length > 0 ? this.photoURLList : null,
            videolist: this.videoURLList.length > 0 ? this.videoURLList : null,
            previewlist: this.previewList,
            // photoid: this.beforeCounterPhotoList[detail.index].id,
          })
            .then((result) => {
              this.disable = false;
              Toast.clear();
              if (result.code == 1) {
                // Toast(result.data);
                Dialog.confirm({
                  title: "",
                  message: "报修单提交成功，是否继续报修？",
                  confirmButtonText: "是",
                  cancelButtonText: "否",
                })
                  .then(() => {
                    // 置空
                    this.photoList = [];
                    this.photoURLList = [];
                    this.videoList = [];
                    this.videoURLList = [];
                    this.previewList = [];
                    this.result = "";
                    this.reindex = 0;
                    this.title = "";
                  })
                  .catch(() => {
                    if (this.$route.query.repair == "repair") {
                      // 跳转上一级。
                      this.$router.go(-1);
                    } else {
                      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                        window.webkit.messageHandlers.getDataFormVue.postMessage(
                          {
                            title: "333", //vue给iOS传值
                          }
                        );
                      } else {
                        window.android.onFinish();
                      }
                    }
                  });
              } else {
                // Toast("报修失败");
                Toast(result.message);
              }
            })
            .catch((err) => {
              this.disable = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.disable = false;
        });
    },
    // 获取报修类型
    OrderOrdertype() {
      OrderOrdertype({
        token: this.$route.query[1],
        userid: this.$route.query[0],
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          if (result.data.length > 0) {
            // console.log(result.data);
            result.data.forEach((item) => {
              // let parent = { id: item.id, url: item.typeName };
              this.columns.push(item.typeName);
              this.columnsid.push(item.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    findvideocover(url) {
      console.log(url);
      const video = document.getElementById("upvideo"); // 获取视频对象
      // const video = document.createElement("video") // 也可以自己创建video
      video.src = url; // url地址 url跟 视频流是一样的
      var canvas = document.getElementById("mycanvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth; // 获取视频宽度
        canvas.height = video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式
        console.log(canvas.toDataURL("image/png"));
        this.videoImg = canvas.toDataURL("image/png"); // 截取后的视频封面
      };
    },
    onClickBack(result, title) {
      if (
        result != "" ||
        title != "" ||
        this.videoURLList.length >= 1 ||
        this.photoURLList.length >= 1
      ) {
        Dialog.confirm({
          title: "",
          message: "您已填写报修内容确认取消报修吗？",
          confirmButtonText: "是",
          cancelButtonText: "否",
        })
          .then(() => {
            // 置空
            if (this.$route.query.repair == "repair") {
              // 跳转上一级。
              this.$router.go(-1);
            } else {
              if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                window.webkit.messageHandlers.getDataFormVue.postMessage({
                  title: "333", //vue给iOS传值
                });
              } else {
                window.android.onFinish();
              }
            }
          })
          .catch(() => {});
        return;
      } else {
        if (this.$route.query.repair == "repair") {
          // 跳转上一级。
          this.$router.go(-1);
        } else {
          if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            window.webkit.messageHandlers.getDataFormVue.postMessage({
              title: "333", //vue给iOS传值
            });
          } else {
            window.android.onFinish();
          }
        }
      }
    },
    //获取当前年月日时分秒
    getdateTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      let gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      return gettime;
    },
  },
  mounted: function () {
    this.OrderOrdertype();
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background:#f8f8f8");
    });
  },
  beforeUnmount() {
    document.body.removeAttribute("style");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div .main {
  width: 100%;
  height: max-content;
  padding-top: 0px;
  background-color: #f8f8f8;
  padding-bottom: 100px;
}
.popCla {
  background: red;
  width: 340px;
  height: 240px;
}
div h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
}
div h6 {
  margin: 10px 20px 10px 20px;
  color: rgb(198, 202, 202);
  text-align: left;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
