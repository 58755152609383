<template>
  <div
    style="
      padding-top: 45px;
      position: absolute;
      width: 100%;
      height: 94%;
      background-color: #f8f8f8;
    "
  >
    <van-nav-bar
      title="评价"
      left-arrow
      @click-left="onClickLeft"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <br />
    <van-cell-group
      inset
      style="height: 40px; padding-top: 15px; padding-left: 15px"
    >
      <span style="color: #646566; font-size: 14px">服务评分：</span>
      <van-rate
        v-model="score"
        :size="20"
        color="#00b4e5"
        style="margin-left: 28px"
      />
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-field
        v-model.trim="message"
        rows="5"
        autosize
        label="服务评价："
        white-space:pre
        clearable="true"
        type="textarea"
        maxlength="100"
        placeholder="请输入评价"
        show-word-limit
      />
    </van-cell-group>

    <div class=".button">
      <van-button
        style="
          width: 95%;
          margin-left: 10px;
          margin-top: 50px;
          margin-bottom: 10px;
          position: fixed;
          left: 0px;
          bottom: 0;
          _position: absolute;
        "
        round
        type="primary"
        size="large"
        color="rgb(30, 177, 227)"
        @click="submit()"
        >评价</van-button
      >
    </div>
    <van-dialog
      v-model:show="showevaldialog"
      message="您已填写评价内容，是否放弃本次评价？"
      show-cancel-button
      confirm-button-text="是"
      cancel-button-text="否"
      @confirm="EvalSure"
    >
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { EvaluateOrder } from "../api";
export default {
  data() {
    return {
      score: 0,
      message: "",
      showevaldialog: false,
    };
  },
  methods: {
    EvalSure() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    onClickLeft() {
      if ((this.score > 0) | (this.message.length > 0)) {
        this.showevaldialog = true;
      } else {
        // 跳转上一级。
        this.$router.go(-1);
      }
    },
    submit() {
      if (this.score < 1) {
        Toast("请为本次服务评分");
        return;
      }
      if (this.score < 5) {
        if (this.message.length == 0) {
          Toast("为了更好的为您提供服务，请对本次服务进行评价");
          return;
        }
      }
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      EvaluateOrder({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
        score: this.score,
        receivecontent: this.message,
      })
        .then((result) => {
          Toast.clear();
          if (result.code == 1) {
            Toast(result.data);
            this.$router.go(-2);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.clear();
          // this.finished = true;
          Toast("网络错误");
        });
    },
  },
};
</script>

<style scoped>
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
.itemBorder {
  border: 1px solid #02a7f0;
  border-radius: 10px;
  padding: 5px;
}
/* .button {
  background-color: #fff;
  padding-top: 10px;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 55px;
} */
</style>>