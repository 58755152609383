<template>
  <div class="main" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="门店"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <div>
      <br />
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item>
      </van-swipe> -->
      <van-cell-group inset>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#00b4e5">
          <van-swipe-item v-for="image in images" :key="image">
            <img style="border-radius: 12px" class="my-image" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </van-cell-group>
      <br />
    </div>
    <van-cell-group inset>
      <van-cell
        title-style="flex:0.5"
        v-for="(item, index) in list"
        :key="item"
        :title="item.name"
        :value="item.content"
        @click="callPhone(index)"
      />
    </van-cell-group>
  </div>
</template>

<script>
// import axios from "axios";

import { Toast } from "vant";
import { store } from "../api";

export default {
  data() {
    return {
      title: "",
      //   list: ["名称：", "地址：", "联系人：", "固定电话：", "开业时间"],
      list: [],
      images: [],
      lianxiPhone: "",
      zuojiPhone: "",
    };
  },
  methods: {
    GetUserInfo() {
      store({
        token: this.$route.query[1],
        userid: this.$route.query[0],
        storeid: this.$route.query[4],
      })
        .then((result) => {
          if (result.code == 1) {
            let data = result.data.inFoStore;
            this.images = result.data.urlList;
            // console.log(data);
            this.list.push({ name: "名称：", content: data.storeName });
            this.list.push({ name: "地址：", content: data.address });
            this.list.push({
              name: "联系人：",
              content: data.contacts,
            });
            this.list.push({
              name: "联系电话：",
              content: data.phone,
            });
            this.list.push({ name: "固定电话：", content: data.telephone });
            this.list.push({ name: "开业时间：", content: data.openingTime });
            this.lianxiPhone = data.phone;
            this.zuojiPhone = data.telephone;
          } else {
            Toast(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack() {
      if (this.$route.query.repair == "repair") {
        // 跳转上一级。
        this.$router.go(-1);
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    callPhone(index) {
      switch (index) {
        case 3:
          if (this.lianxiPhone) {
            // console.log(1);
            this.callP(this.lianxiPhone);
          }
          break;
        case 4:
          if (this.zuojiPhone) {
            this.callP(this.zuojiPhone);
          }
          break;
        default:
          break;
      }
    },
    callP(phone) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
  },
  mounted: function () {
    this.GetUserInfo();
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-width;
  position: absolute;
  background-color: #f8f8f8;
}
.my-swipe .van-swipe-item {
  text-align: center;
  background-color: #f8f8f8;
}
.my-image {
  width: 100%;
  height: 166px;
  object-fit: fill;
}
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
