// 刚刚封装的axios
import request from "./axios";
import Qs from "qs"; // 传参需要用qs转换一下
// 订单列表
export const OrderList = (query) => {
  return request({
    url: "/order/orderlist",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 订单详情
export const OrderDetails = (query) => {
  return request({
    url: "/order/orderdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 订单取消
export const ReceiveOrder = (query) => {
  return request({
    url: "/order/receiveorder",
    method: "post",
    data: Qs.stringify(query),
  });
};
//评价订单
export const EvaluateOrder = (query) => {
  return request({
    url: "/order/evaluateorder",
    method: "post",
    data: Qs.stringify(query),
  });
};
//验收和返工
export const AcceptanceOrRework = (query) => {
  return request({
    url: "/order/acceptanceorrework",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 上传单个图片
export const OrderAddphoto = (query) => {
  return request({
    url: "/order/addphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传单个视频
export const OrderAddvideo = (query) => {
  return request({
    url: "/order/addvideo",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 创建报修单
export const OrderAddorder = (query) => {
  return request({
    url: "/order/addorder",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};

// 报修类型
export const OrderOrdertype = (query) => {
  return request({
    url: "/order/ordertype",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 删除单个图片
export const OrderDeletephoto = (query) => {
  return request({
    url: "/order/deletephoto",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 上传单个视频
export const OrderAddAV = (query) => {
  return request({
    url: "/order/testaddvideo",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 门店详情
export const store = (query) => {
  return request({
    url: "store/store",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 门店列表
export const getStoreList = (query) => {
  return request({
    url: "store/getstorelist",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 根据搜索条件查询所有订单
export const getConditionOrder = (query) => {
  return request({
    url: "/order/getconditionorder",
    method: "post",
    data: Qs.stringify(query),
  });
};
