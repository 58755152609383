<template>
  <div class="articleList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >{{ menuName }}订单数：{{ total }}单</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <div v-if="zRoleid == 2">
              <span>{{ item.storeName }}</span>
              <br />
            </div>
            <span class="reback" v-if="item.reworkFlag == 1 ? true : false"
              >【返工】</span
            >
            <span class="projectName">{{ item.orderTypeName }}</span>
            <br />
            <span class="projectName"
              >{{ zName }}时间：{{ formatDate(item.updateTime) }}</span
            >
            <br />
            <span style="color: rgb(150, 151, 153)">{{
              item.orderContent
            }}</span>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { OrderList } from "../api";
// import { Toast } from "_vant@3.4.5@vant";
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const userid = ref(props.channel.z_userid);
    const token = ref(props.channel.z_token);
    const menuName = ref(props.channel.name);
    const zName = ref(props.channel.z_name);
    const zRoleid = ref(props.channel.z_roleid);
    const zActive = ref(props.channel.z_active);
    const total = ref("");
    const onLoad = () => {
      OrderList({
        page: pageNum.value,
        size: 100,
        userid: userid.value,
        token: token.value,
        orderstatus: menuName.value,
      })
        .then((result) => {
          if (refreshing.value) {
            list.value = [];
            refreshing.value = false;
          }
          list.value.push(...result.data.list);
          total.value = result.data.total;
          loading.value = false;
          if (result.data.hasNextPage) {
            pageNum.value = result.data.nextPage;
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      pageNum,
      userid,
      token,
      menuName,
      zName,
      zRoleid,
      zActive,
      total,
    };
  },
  data() {
    return {};
  },
  methods: {
    jump(item) {
      // console.log(item);
      // Toast(1111);
      this.listData = item;
      this.listData.z_userid = this.userid;
      this.listData.z_token = this.token;
      this.listData.z_roleid = this.zRoleid;
      this.listData.z_active = this.zActive;
      this.listData.z_name = this.zName;
      this.listData.z_reworkFlag = item.reworkFlag;
      this.listData.z_reapirTime = this.formatDate(item.createTime);
      this.$router.push({
        path: "/ListDetails",
        query: this.listData,
      });
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        let minutes =
          dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " " +
          hours +
          ":" +
          minutes
          // +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
  },
};
</script>

<style scoped>
.projectName {
  color: #02a7f0;
}
.reback {
  color: red;
}
.itemBorder {
  border: 1px solid #02a7f0;
  border-radius: 10px;
  padding: 5px;
}
</style>
