<template>
  <div class="mian" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="搜索结果"
      left-arrow
      right-text="首页"
      @click-left="onClickBack"
      fixed
      @click-right="onClickRight"
      safe-area-inset-top
    ></van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >已搜索订单数：{{ total }}单</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <van-tag
              round
              color="#3d76fd"
              v-if="item.orderStatus == '已接单'"
              >{{ item.orderStatus }}</van-tag
            >
            <van-tag
              round
              color="#fa7377"
              v-if="item.orderStatus == '已完工'"
              >{{ item.orderStatus }}</van-tag
            >
            <van-tag
              round
              color="#fe5958"
              v-if="item.orderStatus == '已评价'"
              >{{ item.orderStatus }}</van-tag
            >
            <van-tag
              round
              color="#2fcb9e"
              v-if="item.orderStatus == '已验收'"
              >{{ item.orderStatus }}</van-tag
            >
            <van-tag
              round
              color="#6b37ff"
              v-if="item.orderStatus == '已转派'"
              >{{ item.orderStatus }}</van-tag
            >
            <van-tag
              round
              color="#fed650"
              v-if="item.orderStatus == '未接单'"
              >{{ item.orderStatus }}</van-tag
            >
            <br />
            <span>{{ item.storeName }}</span>
            <br />
            <span class="reback" v-if="item.reworkFlag == 1 ? true : false"
              >【返工】</span
            >
            <span class="projectName">{{ item.orderTypeName }}</span>
            <br />
            <span class="projectName"
              >{{ returnStringTime(item.orderStatus)
              }}{{ formatDate(item.updateTime) }}</span
            >
            <br />
            <span style="color: rgb(150, 151, 153)">{{
              item.orderContent
            }}</span>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { Toast } from "vant";
import { ref } from "vue";
import { getConditionOrder } from "../api";
import { useRoute } from "vue-router";
export default {
  data() {
    return {};
  },
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const remark = ref(route.query.remark);
    const token = ref(route.query.token);
    const userid = ref(route.query.userid);
    const total = ref("");
    const onLoad = () => {
      getConditionOrder({
        token: route.query.token,
        userid: route.query.userid,
        orderstatus: route.query.orderStatus,
        reworkflag: route.query.reworkId,
        storeName: route.query.storeId,
        strdate: route.query.strdate,
        typeid: route.query.repairTypeId,
        enddate: route.query.enddate,
        page: pageNum.value,
        size: 20,
      })
        .then((result) => {
          if (refreshing.value) {
            list.value = [];
            refreshing.value = false;
          }
          list.value.push(...result.data.list);
          if (result.data.total == 0) {
            total.value = result.data.list.length;
          } else {
            total.value = result.data.total;
          }

          loading.value = false;
          if (result.data.hasNextPage) {
            console.log(result.data.nextPage);
            pageNum.value = result.data.nextPage;
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      total,
      remark,
    };
  },
  methods: {
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    onClickRight() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    returnStringTime(string) {
      switch (string) {
        case "未接单":
          return "报修时间：";
        case "已接单":
          return "接单时间：";
        case "已完工":
          return "完工时间：";
        case "已评价":
          return "评价时间：";
        case "已返工":
          return "返工时间：";
        case "已转派":
          return "转派时间：";
        case "已验收":
          return "验收时间：";
        default:
          break;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        let minutes =
          dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " " +
          hours +
          ":" +
          minutes
          // +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    jump(item) {
      console.log("sssss" + this.token);
      // Toast(1111);
      let zActive = 0;
      switch (item.orderStatus) {
        case "未接单":
          zActive = 0;
          break;
        case "已接单":
          zActive = 1;
          break;
        case "已完工":
          zActive = 2;
          break;
        case "已评价":
          zActive = 3;
          break;
        case "已验收":
          zActive = 4;
          break;
        case "已转派":
          zActive = 5;
          break;
        default:
          break;
      }
      this.listData = item;
      this.listData.z_userid = this.userid;
      this.listData.z_token = this.token;
      this.listData.z_roleid = this.remark;
      this.listData.z_active = zActive;
      this.listData.z_reworkFlag = item.reworkFlag;
      this.listData.z_reapirTime = this.formatDate(item.createTime);
      this.$router.push({
        path: "/ListDetails",
        query: this.listData,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  position: absolute;
  background-color: #f8f8f8;
}
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
.reback {
  color: red;
}
.projectName {
  color: #02a7f0;
}
</style>