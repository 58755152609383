<template>
  <div></div>
</template>

<script>
// import { Toast } from "_vant@3.4.5@vant";
export default {
  // 钩子
  mounted: function () {
    window.getDataFromNative = this.getDataFromNative;
    // if (this.userList) {
    //   this.$router.push({
    //     path: "/homePage",
    //     query: this.userList,
    //     // path: "/EvaluationView",
    //     // query: this.userList,
    //   });
    //   // this.$router.push({
    //   //   path: "/TurnSendList",
    //   //   query: this.userList,
    //   // });
    // }
  },
  data() {
    return {
      // userList: [
      //   7,
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI3IiwiZXhwIjoxOTc1MDI0NTMzLCJpYXQiOjE2NTk0MDUzMzN9.A3ctuWAzkl3qzbq_HFZv8QuVFUdpgsoghZBoVFUnlD0",
      //   101,
      //   3,
      //   18,
      // ],
      userList: [],
      content: 0,
    };
  },
  methods: {
    getDataFromNative(params) {
      // console.log(1 + params);
      this.userList = params.split(",");
      // Toast(this.userList[2]);
      // var data = {
      //   name: "xiaomili",
      // };
      // return data; //回调给原生，可写可不写
      if (this.userList && this.content == 0) {
        this.content++;
        switch (this.userList[2]) {
          case "101":
            this.$router.push({
              path: "/RepairView",
              query: this.userList,
            });
            break;
          case "102":
            this.$router.push({
              path: "/homePage",
              query: this.userList,
            });
            break;
          case "103":
            this.$router.push({
              path: "/StoreView",
              query: this.userList,
            });
            break;
          case "106":
            this.$router.push({
              path: "/OrderSearch",
              query: this.userList,
            });
            break;
        }
      }
    },
  },
};
</script>

<style></style>
