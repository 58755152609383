<template>
  <div class="main" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="搜索"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
    <!-- 报修门店 -->
    <br v-if="remark == '2'" />
    <van-cell-group inset v-if="remark == '2'">
      <van-field
        v-model="storeName"
        rows="3"
        autosize
        label="报修门店"
        type="textarea"
        placeholder="请输入报修门店"
        maxlength="50"
        show-word-limit
      />
    </van-cell-group>
    <!-- <van-cell-group inset v-if="remark == '2'">
      <van-field
        v-model="storeName"
        is-link
        readonly
        label="报修门店"
        placeholder="请选择报修门店"
        @click="showStroePicker = true"
      />

      <van-popup v-model:show="showStroePicker" round position="bottom">
        <van-picker
          :columns="storeList"
          @cancel="showStroePicker = false"
          @confirm="onStroeListConfirm"
        />
      </van-popup>
    </van-cell-group> -->
    <!-- 报修类型 -->
    <br />
    <van-cell-group inset>
      <van-field
        v-model="repairType"
        is-link
        readonly
        label="报修类型"
        placeholder="请选择报修类型"
        @click="showTypePicker = true"
      />

      <van-popup v-model:show="showTypePicker" round position="bottom">
        <van-picker
          :columns="repairTypeList"
          @cancel="showTypePicker = false"
          @confirm="onTypeConfirm"
        />
      </van-popup>
    </van-cell-group>
    <!-- 订单状态 -->
    <br />
    <van-cell-group inset>
      <van-field
        v-model="orderStatus"
        is-link
        readonly
        label="订单状态"
        placeholder="请选择订单状态"
        @click="showOrderStatusPicker = true"
      />

      <van-popup v-model:show="showOrderStatusPicker" round position="bottom">
        <van-picker
          :columns="remark == '2' ? OrderStatuslistLeader : OrderStatuslist"
          @cancel="showOrderStatusPicker = false"
          @confirm="onOrderStatusConfirm"
        />
      </van-popup>
    </van-cell-group>
    <!-- 返工状态 -->
    <br />
    <van-cell-group inset>
      <van-field
        v-model="rework"
        is-link
        readonly
        label="是否返工"
        placeholder="请选择是否返工"
        @click="showReworkPicker = true"
      />

      <van-popup v-model:show="showReworkPicker" round position="bottom">
        <van-picker
          :columns="reworkList"
          default-index="2"
          @cancel="showReworkPicker = false"
          @confirm="onReworkConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-field
        v-model="dateTime"
        is-link
        readonly
        label="日期区间"
        placeholder="请选择日期区间"
        @click="showDatePicker = true" />
      <van-calendar
        v-model:show="showDatePicker"
        :min-date="minDate"
        type="range"
        color="#00b4e5"
        @confirm="onDateConfirm"
        :show-confirm="false"
    /></van-cell-group>
    <van-button
      style="
        width: 95%;
        margin-left: 10px;
        margin-top: 50px;
        margin-bottom: 10px;
        position: fixed;
        left: 0px;
        bottom: 0;
        _position: absolute;
      "
      round
      type="primary"
      size="large"
      color="rgb(30, 177, 227)"
      @click="submit"
      :disabled="disable"
      >搜索</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
// import { getStoreList } from "../api";
import { OrderOrdertype } from "../api";
export default {
  data() {
    return {
      // showStroePicker: false,
      showTypePicker: false,
      showOrderStatusPicker: false,
      showReworkPicker: false,
      showDatePicker: false,
      remark: this.$route.query[3],
      minDate: new Date("2022/01/01"),
      orderStatus: "",
      storeName: "",
      repairType: "",
      rework: "",
      strdate: "",
      enddate: "",
      // storeList: [],
      // storeIdList: [],
      storeId: [],
      repairTypeList: [],
      repairTypeIdList: [],
      repairTypeId: [],
      OrderStatuslistLeader: [
        "全部",
        "未接单",
        "已接单",
        "已完工",
        "已评价",
        "已验收",
        "已转派",
      ],
      OrderStatuslist: [
        "全部",
        "未接单",
        "已接单",
        "已完工",
        "已评价",
        "已转派",
      ],

      reworkList: ["全部", "是", "否"],
    };
  },
  methods: {
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        // let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        // let minutes =
        //   dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
          // +
          // hours +
          // ":" +
          // minutes
          //+
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    // 获取报修类型
    getData() {
      // getStoreList({
      //   token: this.$route.query[1],
      //   userid: this.$route.query[0],
      // })
      //   .then((result) => {
      //     // this.columns.push(...result.data);
      //     // 循环创建iamgeList
      //     // if (result.data.length > 0) {
      //     if (result.code == 1) {
      //       result.data.forEach((item) => {
      //         this.storeList.push(item.storeName);
      //         this.storeIdList.push(item.id);
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     // this.finished = true;
      //     Toast("网络错误");
      //   });
      OrderOrdertype({
        token: this.$route.query[1],
        userid: this.$route.query[0],
      })
        .then((result) => {
          if (result.code == 1) {
            result.data.forEach((item) => {
              this.repairTypeList.push(item.typeName);
              this.repairTypeIdList.push(item.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    // onStroeListConfirm(value, index) {
    //   this.storeName = value;
    //   this.storeId = this.storeIdList[index];
    //   console.log(this.storeId);
    //   this.showStroePicker = false;
    // },
    onTypeConfirm(value, index) {
      this.repairType = value;
      this.repairTypeId = this.repairTypeIdList[index];
      this.showTypePicker = false;
      console.log(value + "" + index);
    },
    onOrderStatusConfirm(value) {
      this.orderStatus = value;
      this.showOrderStatusPicker = false;
    },
    onReworkConfirm(value) {
      this.rework = value;
      this.showReworkPicker = false;
    },
    onDateConfirm(value) {
      this.strdate = this.formatDate(value[0]);
      this.enddate = this.formatDate(value[1]);
      this.dateTime = `${this.formatDate(value[0])} - ${this.formatDate(
        value[1]
      )}`;
      this.showDatePicker = false;
    },
    submit() {
      let searchCriteria = {};
      let reworkId = "";
      switch (this.rework) {
        case "是":
          reworkId = "1";
          break;
        case "否":
          reworkId = "0";
          break;
        default:
          reworkId = "";
          break;
      }
      if (this.storeName == null) {
        this.storeName = "";
      }
      if (this.repairTypeId == null) {
        this.repairTypeId = "";
      }
      if (this.orderStatus == null || this.orderStatus == "全部") {
        this.orderStatus = "";
      }
      if (this.enddate == null) {
        this.enddate = "";
      }
      if (this.strdate == null) {
        this.strdate = "";
      }
      searchCriteria.enddate = this.enddate;
      searchCriteria.orderStatus = this.orderStatus;
      searchCriteria.reworkId = reworkId;
      searchCriteria.storeId = this.storeName;
      searchCriteria.strdate = this.strdate;
      searchCriteria.repairTypeId = this.repairTypeId;
      searchCriteria.token = this.$route.query[1];
      searchCriteria.userid = this.$route.query[0];
      searchCriteria.remark = this.$route.query[3];
      this.$router.push({
        path: "/SearchList",
        query: searchCriteria,
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  background-color: #f8f8f8;
}
.van-nav-bar {
  background-color: #00b4e5;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>